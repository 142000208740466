<template>
  <v-sheet
    elevation="3"
    rounded
    class="mb-4"
  >
    <v-container
      fluid
    >
      <v-row
        dense
      >
        <v-col
          cols="12"
          lg="4"
          md="4"
        >
          <StoreSelector
            v-model="filters['store.id']"
            filter
          />
        </v-col>
        <v-col
          cols="12"
          lg="4"
          md="4"
        >
          <DropsSelector
            v-model="filters['drop.id']"
            filter
          />
        </v-col>
        <v-spacer />
        <v-col
          cols="12"
          lg="1"
          class="align-end"
        >
          <v-btn
            v-model="loading"
            small
            color="blue lighten-1"
            outlined
            fixed
            right
            fab
            @click="refresh"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import { debounce } from '@/helpers/debounce'
import DropsSelector from '@/components/base/Product/Input/DropsSelector.vue'
import EventBus from '@/plugins/event-bus'
import StoreSelector from '@/components/base/StoreSelector.vue'

export default {
  name: 'PhotoFilters',
  components: { StoreSelector, DropsSelector },
  props: {
    loading: Boolean,
  },
  data() {
    return {
      filters: {
        'drop.id': this.$route.query['drop.id'],
        'store.id': this.$route.query['store.id'],
        position: this.$route.query.position,
      },
    }
  },
  watch: {
    filters: {
      handler: debounce(function (value) {
        this.$emit('onFiltersChanged', value)
      }, 500),
      deep: true,
    },
  },
  methods: {
    refresh() {
      EventBus.$emit('PHOTOS_REFRESH_LIST', {})
    },
  },
}
</script>
